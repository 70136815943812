import React from "react";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">From Our Users</h1>
        <p className="primary-text">
          We reward people all around the world.
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={`john-doe-image.png`} alt="" />
        <p>
        I can now shop on Amazon without paying thanks to all the points I've earned through RewardRocket efforts, all from home!
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>James C.</h2>
      </div>
    </div>
  );
};

export default Testimonial;