import React from 'react'
import RedeemPaypalMethod from '../../components/RedeemMethod/RedeemPaypalMethod'
import classes from './redeemPage.module.css'
const Spacer = require('react-spacer')

export default function RedeemPaypal() {
  return (
    <>    
    <h2 className={classes.sponsoredOffersHeading}>Choose your Reward Amount:</h2>
    <RedeemPaypalMethod/>
    <Spacer height={'100px'}/>
    </>
  )
}
