import React, { useState } from 'react';
import './wallOffer.css';
import ConversionEvents from '../ConversionEvents/ConversionEvents';

const WallOffer = ({
  title_text,
  center_text,
  image_game,
  image_device,
  image_Trophy,
  link,
  image_coin,
  reward_payout,
  events
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const truncatedReward = parseFloat(reward_payout).toFixed(1);

  return (
    <>
      <div className="card">
        <div className="first">
          <div className="gameLogo">
            <img src={image_game} alt="" width={100} />
          </div>
          <div className="android">
            <img src={image_device} alt="" width={30} />
          </div>
        </div>
        <div className="second">
          <div className="heading">
            <h1 className="poppins-bold">{title_text}</h1>
          </div>
          <div className="level">
            <p className="poppins-semibold">{center_text}</p>
          </div>
        </div>
        <div className="third" onClick={openModal}>
          <div>
            <p className="poppins-bold">{truncatedReward} coins</p>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>×</button>
            <ConversionEvents
              description={center_text}
              events={events}
              totalReward={truncatedReward}
              link={link}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WallOffer;