import React from 'react'
import { useAuth } from '../../hooks/useAuth'

export default function BitLabs() {

    const { user } = useAuth();
  return (
    <iframe width="100%" height="800px" border="0" padding="0" margin="0" scrolling="yes" frameborder="0" src={`https://web.bitlabs.ai/?uid=${user.id}&token=08d16df8-0b66-43ed-acff-ec104c40a1e2`}></iframe>

  )
}
