import AppRoutes from "./AppRoutes";
import OfferWallAppRoutes from "./OfferWallAppRoutes";
import Header from "./components/Header/Header"
import Loading from "./components/Loading/Loading";
import { useLoading } from "./hooks/useLoading";
import { setLoadingInterceptor } from './interceptors/loadingInterceptor';
import { useEffect } from "react";
import Footer from "./components/Footer/Footer";
import OfferWallHeader from "./components/OfferWallHeader/OfferWallHeader";

function App() {
  const { showLoading, hideLoading } = useLoading();
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  useEffect(() => {
    setLoadingInterceptor({ showLoading, hideLoading });
  }, []);
  return (<>
  {subdomain === 'offerwall' ? (
        <>
        <OfferWallHeader/>
        <OfferWallAppRoutes />  
        </>
      ) : (
        <>
        <Header/>
        <AppRoutes />
        </>    // Use AppRoutes component for the main app
      )}
  <Footer/>
  </>)
}

export default App;
