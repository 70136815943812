import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/Landing/Landing.js';
import LoginPage from './pages/Login/LoginPage';
import OffersPage from './pages/Offers/OffersPage';
import RedeemPage from './pages/Redeem/RedeemPage.js';
import RegisterPage from './pages/Register/RegisterPage';
import CPXResearch from './pages/Offers/CPXResearch.js';
import TheoremReach from './pages/Offers/TheoremReach.js';
import Revlum from './pages/Offers/Revlum.js';
import Wannads from './pages/Offers/Wannads.js';
import BitLabs from './pages/Offers/BitLabs.js';
import InBrain from './pages/Offers/InBrain.js';
import Terms from './pages/Terms/Terms.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js';
import MyChips from './pages/Offers/MyChips.js';
import RedeemPaypal from './pages/Redeem/RedeemPaypal.js';
import RedeemVisa from './pages/Redeem/RedeemVisa.js';
import RedeemAmazon from './pages/Redeem/RedeemAmazon.js';
import RedeemCoinbase from './pages/Redeem/RedeemCoinbase.js';
import ContactForm from './components/ContactForm/ContactForm.js';
import KiwiWall from './pages/Offers/KiwiWall.js';
import SurveyList from './pages/Surveys/Surveys.js';
import OffersOnly from './pages/OffersOnly/OffersOnly.js';
import Lootably from './pages/Offers/Lootably.js';
import Adscend from './pages/Offers/Adscend.js';
import Games from './pages/Games/Games.js';

export default function AppRoutes() {
  return <Routes>
    <Route path='/' element={<HomePage/>}/>
    <Route path='/tasks' element={<OffersPage/>}/>
    <Route path='/login' element={<LoginPage/>}/>
    <Route path='/register' element={<RegisterPage/>}/>
    <Route path='/landing' element={<HomePage/>}/>
    <Route path='/redeem' element={<RedeemPage/>}/>
    <Route path='/contact' element={<ContactForm/>}/>
    <Route path='/survey/cpx' element={<CPXResearch/>}/>
    <Route path='/survey/theoremreach' element={<TheoremReach/>}/>
    <Route path='/survey/bitlabs' element={<BitLabs/>}/>
    <Route path='/survey/inbrain' element={<InBrain/>}/>
    <Route path='/offerwall/revlum' element={<Revlum/>}/>
    <Route path='/offerwall/wannads' element={<Wannads/>}/>
    <Route path='/offerwall/mychips' element={<MyChips/>}/>
    <Route path='/offerwall/lootably' element={<Lootably/>}/>
    <Route path='/offerwall/adscend' element={<Adscend/>}/>
    <Route path='/surveys' element={<SurveyList/>}/>
    <Route path='/offers' element={<OffersOnly/>}/>
    <Route path='/games' element={<Games/>}/>
    <Route path='/terms' element={<Terms/>}/>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
    <Route path='/redeem/paypal' element={<RedeemPaypal/>}/>
    <Route path='/redeem/visa' element={<RedeemVisa/>}/>
    <Route path='/redeem/amazon' element={<RedeemAmazon/>}/>
    <Route path='/redeem/coinbase' element={<RedeemCoinbase/>}/>


  </Routes>
}
