import React from 'react'
import classes from './recentEarnerCard.module.css';

export default function RecentEarnerCard({ amount, username }) {
  return (
    <button className={classes.btn}>
        ${amount}
        <span>{username}</span>
    </button>
  );
}
