import React from 'react'
import RedeemAmazonMethod from '../../components/RedeemMethod/RedeemAmazonMethod';
import classes from './redeemPage.module.css';
const Spacer = require('react-spacer')



export default function RedeemAmazon() {
  return (
    <>
    <h2 className={classes.sponsoredOffersHeading}>Choose your Reward Amount:</h2>
    <RedeemAmazonMethod/>
    <Spacer height={'100px'}/>
    </>
  )
}
