import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ContactForm from './components/ContactForm/ContactForm.js';
import HomePage from './pages/Landing/Landing.js';
import OfferWall from './pages/OfferWall/OfferWall.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js';
import Terms from './pages/Terms/Terms.js';

export default function OfferWallAppRoutes() {
  return <Routes>
    <Route path='/demo' element={<OfferWall/>}/>
    <Route path='/' element={<OfferWall/>}/>
    <Route path='/contact' element={<ContactForm/>}/>
    <Route path='/terms' element={<Terms/>}/>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>

  </Routes>
}
