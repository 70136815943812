import React from 'react'
import RedeemVisaMethod from '../../components/RedeemMethod/RedeemVisaMethod';
import classes from './redeemPage.module.css';
const Spacer = require('react-spacer')


export default function RedeemVisa() {
  return (
    <>
    <h2 className={classes.sponsoredOffersHeading}>Choose your Reward Amount:</h2>
    <RedeemVisaMethod/>
    <Spacer height={'100px'}/>
    </>
  )
}
