import React from 'react';
import { motion } from 'framer-motion';
import RecentEarnerCard from './RecentEarnerCard';
import { useRef, useEffect, useState } from 'react';
import classes from './recentEarnerCarousel.module.css';


export default function RecentEarnerCarousel() {

  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  return (
    <div className={classes.App}>
        <motion.div ref={carousel} className={classes.carousel}>
            <motion.div drag="x" dragConstraints={{right: 0, left: -width}} className={classes.innerCarousel}>
                <motion.div className={classes.item}>
                <RecentEarnerCard amount={"15.50"} username={"wafflefreak33"} className={classes.card}/>
                </motion.div>
                <motion.div className={classes.item}>
                <RecentEarnerCard amount={"10.00"} username={"JOHND"} className={classes.card}/>
                </motion.div>
                <motion.div className={classes.item}>
                <RecentEarnerCard amount={"24.10"} username={"shcoobi"} className={classes.card}/>
                </motion.div>
                <motion.div className={classes.item}>
                <RecentEarnerCard amount={"8.70"} username={"reward_33"} className={classes.card}/>
                </motion.div>
            </motion.div>
        </motion.div>
    </div>
  );
}
