import axios from "axios";

export const getLootablyGamingOffers = async () => {
    const { data } = await axios.get('api/lootablyoffers/gaming', {
        headers: {
            "Content-Type": 'application/json'
        }
    });
    return data;
};

export const getLootablyOffers = async () => {
    const { data } = await axios.get('api/lootablyoffers/offers', {
        headers: {
            "Content-Type": 'application/json'
        }
    });
    return data;
};