import axios from "axios";

export const getUserGeo = async (ip) => {
    const { data } = await axios.get('api/usergeo', {
        headers: {
            "Content-Type": 'application/json'
        },
        params: {
            ip: ip
        }
    });
    return data;
};