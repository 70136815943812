import React from 'react'
import RedeemAmazonMethod from '../../components/RedeemMethod/RedeemAmazonMethod';
import RedeemCoinbaseMethod from '../../components/RedeemMethod/RedeemCoinbaseMethod';
import classes from './redeemPage.module.css';
const Spacer = require('react-spacer')



export default function RedeemCoinbase() {
  return (
    <>
    <h2 className={classes.sponsoredOffersHeading}>Choose your Reward Amount:</h2>
    <RedeemCoinbaseMethod/>
    <Spacer height={'100px'}/>
    </>
  )
}
