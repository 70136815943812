import React from 'react'
import { Helmet } from 'react-helmet'
import WallOffer from '../../components/WallOffer/WallOffer'
import classes from './offerWall.module.css'

export default function OfferWall() {
  return (
    <>
    <div className={classes.heading}>
    <h2 className={classes.sponsoredOffersHeading}>Earn Rewards!</h2>
    </div>
    <ul className={classes.list}>
        <li className={classes.item}>
    <WallOffer
          title_text={"Gamefly - Video Game Rentals Delivered"}
          center_text={"Purchase a pre-paid plan starting at only $12!"}
          image_game={"https://www.lduhtrp.net/image-101192354-10495782"}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.kqzyfj.com/click-101192354-10480547'}
        />
        </li>
        <li className={classes.item}>
    <WallOffer
          title_text={"Nord VPN"}
          center_text={"Purchase a one-month subscription 67% off!"}
          image_game={'https://www.ftjcfx.com/image-101192354-13795051'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.dpbolvw.net/click-101192354-15743313'}
        />
        </li>
        <li className={classes.item}>
    <WallOffer
          title_text={"Jobber"}
          center_text={"Create an account and start a free trial!"}
          image_game={'/jobber.svg'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.anrdoezrs.net/click-101192354-13807884'}
        />
        </li>
        <li className={classes.item}>
    <WallOffer
          title_text={"Opera Desktop Browser"}
          center_text={"Install the browser for free"}
          image_game={'/opera.svg'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.jdoqocy.com/click-101192354-15333675'}
        />
        </li>
        <li className={classes.item}>
    <WallOffer
          title_text={"Password Depot"}
          center_text={"Request a quote for the Enterprise Server!"}
          image_game={'/passworddepotlogo.svg'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.anrdoezrs.net/click-101192354-15649045'}
        />
        </li>
        <li className={classes.item}>
    <WallOffer
          title_text={"Shopper's Voice"}
          center_text={"Complete the survey!"}
          image_game={'/shoppersvoicelogo.png'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.jdoqocy.com/click-101192354-15713117'}
        />
        </li>
        <li className={classes.item}>
        <WallOffer
          title_text={"SRFax - Secure Online Faxing"}
          center_text={"Complete the survey!"}
          image_game={'/srfaxlogo.svg'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.tkqlhce.com/click-101192354-15153863'}
        />
        </li>
        <li className={classes.item}>
        <WallOffer
          title_text={"Shopper's Voice"}
          center_text={"Complete the survey!"}
          image_game={'/shoppersvoicelogo.png'}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          image_coin={'/coin.png'}
          link={'https://www.jdoqocy.com/click-101192354-15713117'}
        />
        </li>

    </ul>
    </>
  )
}
