import React from 'react';
import './Landing.css'
import Hero from '../../components/Hero/Hero';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import Testimonial from '../../components/Testimonial/Testimonial';
import RegisterPage from '../Register/RegisterPage';
import Footer from '../../components/Footer/Footer';

export default function HomePage() {
  return (
    <div className="Landing">
      <Hero />
      <HowItWorks />
      <Testimonial />
      
    </div>
  )
}
