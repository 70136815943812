import React from 'react'
import classes from './privacyPolicy.module.css'

export default function PrivacyPolicy() {
  return (
    <div className={classes.main}>
        <h1 className={classes.heading}>Privacy Policy</h1>

<p className={classes.subsection}><strong>Last Updated:</strong> February 15, 2024</p>

<p className={classes.subsection}>RewardRocket ("we," "us," or "our") operates the website rewardrocket.app (the "Site"), which offers rewarded surveys and offers to users ("you" or "your"). This Privacy Policy outlines how we collect, use, and disclose your personal information when you use our Site.</p>

<h2 className={classes.section}>1. Information We Collect</h2>

<p className={classes.subsection}>a. <strong>Personal Information:</strong> When you register on our Site or participate in surveys, we may collect personal information such as your name, email address, postal address, phone number, date of birth, demographic information, and other information you provide.</p>

<p className={classes.subsection}>b. <strong>Survey Responses:</strong> We collect information provided by you when you participate in surveys, including your opinions, preferences, and other responses.</p>

<p className={classes.subsection}>c. <strong>Usage Data:</strong> We may collect information about your interactions with our Site, including your IP address, browser type, operating system, pages visited, and other usage data.</p>

<h2 className={classes.section}>2. Use of Information</h2>

<p className={classes.subsection}>a. <strong>Providing Services:</strong> We use your personal information to provide and improve our services, including administering surveys, managing your account, and communicating with you.</p>

<p className={classes.subsection}>b. <strong>Research and Analysis:</strong> We may aggregate and analyze survey responses and usage data to conduct research, generate insights, and improve our services.</p>

<p className={classes.subsection}>c. <strong>Marketing:</strong> With your consent, we may use your personal information to send you promotional materials, offers, and other communications about our products and services.</p>

<h2 className={classes.section}>3. Disclosure of Information</h2>

<p className={classes.subsection}>a. <strong>Service Providers:</strong> We may share your personal information with third-party service providers who assist us in operating our Site, conducting surveys, and delivering services to you.</p>

<p className={classes.subsection}>b. <strong>Legal Compliance:</strong> We may disclose your personal information when required by law or in response to valid requests by public authorities.</p>

<p className={classes.subsection}>c. <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.</p>

<h2 className={classes.section}>4. Data Security</h2>

<p className={classes.subsection}>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>

<h2 className={classes.section}>5. Your Choices</h2>

<p className={classes.subsection}>You may choose not to provide certain personal information, although this may limit your ability to use certain features of our Site.</p>

<p className={classes.subsection}>You may opt out of receiving marketing communications from us by following the instructions provided in such communications.</p>

<h2 className={classes.section}>6. Third-Party Links</h2>

<p className={classes.subsection}>Our Site may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. We encourage you to review the privacy policies of these third parties.</p>

<h2 className={classes.section}>7. Children's Privacy</h2>

<p className={classes.subsection}>Our Site is not intended for children under the age of 18, or under the age of 16 with the permission of a guardian. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</p>

<h2 className={classes.section}>8. Changes to this Privacy Policy</h2>

<p className={classes.subsection}>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

<p className={classes.subsection}>By using our Site, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.</p>

<h2 className={classes.section}>9. Contact Us</h2>

<p className={classes.subsection}>If you have any questions about the rights and restrictions above, please contact us at cashoutnowllc@gmail.com</p>

    </div>
  )
}
