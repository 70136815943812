import React from 'react'
import { useAuth } from '../../hooks/useAuth';
import classes from './modal.module.css';
import axios from 'axios';


export default function Modal({ closeModal, coin_amount, method }) {

    const { user } = useAuth();
    async function processRedemption() {
        const user_id = user.id;
        const username = user.username;
        const email = user.email;
        const payout_amount = coin_amount/100;
        await axios.post('https://www.rewardrocket.app/api/redeem', { user_id, username, email, coin_amount, payout_amount, method });
        closeModal(false);
    }

  return (<div className={classes.background}>
    <div className={classes.container}>
        <div className={classes.xButton}>
        <button onClick={() => closeModal(false)}>X</button>
        </div>
        <div className={classes.title}>
            <h1>Confirm Your Reward:</h1>
        </div>
        <div className={classes.body}>
            <p>You will receive your chosen reward by email. If this is a PayPal transaction, make sure that your
                PayPal email address is the same as your RewardRocket email address.
            </p>
        </div>
        <div className={classes.footer}>
            <button onClick={() => closeModal(false)} id="cancelbtn">Cancel</button>
            <button onClick={() => processRedemption()}>Get Reward!</button>
        </div>
    </div>
  </div>
  );
}
