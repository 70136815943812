import React from 'react'
import { useAuth } from '../../hooks/useAuth'

export default function TheoremReach() {

    const { user } = useAuth();
  return (
    <iframe width="100%" frameBorder="0" height="1000px" src={`https://theoremreach.com/respondent_entry/direct?api_key=961bbbd0e5fd7826f858146d9671&user_id=${user.id}&transaction_id=1`}></iframe>
  )
}
