import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import RecentEarnerCard from '../RecentEarnerCard/RecentEarnerCard';
import RecentEarnerCarousel from '../RecentEarnerCard/RecentEarnerCarousel';
import classes from './header.module.css';

export default function Header() {
    const {user, logout} = useAuth();
    const [coins, setCoins] = useState(user?.coins || 0); // Initialize with existing coins

    useEffect(() => {
        const pollData = async () => {
            if (user) {
            const response = await axios.get(`https://www.rewardrocket.app/api/users/coins-count?user_id=${user.id}`);
            setCoins(response.data.coins); // Assuming response contains updated coins
            }
        };

        const intervalId = setInterval(pollData, 5000); // Poll every 5 seconds

        return () => clearInterval(intervalId); // Clear interval on unmount
    }, [user]); // Re-run effect if user changes


    return <header className={classes.header}>
        <div className={classes.container}>
            <Link to="/" className={classes.logo}>
            <img
              src={`/Xbznpd01.svg`}
              alt={'logo'}
            />
            </Link>
            <nav>
            <RecentEarnerCarousel/>            
                <ul>
                    <Link to="/contact">Support</Link>
                {user && <Link to="/tasks">Tasks</Link>}
                {user && <Link to="/redeem">Redeem</Link>}
                {user && <li>
                        <Link to="/redeem" className={classes.coinLogo}>
                        <img
                        src={`/purplecoin.svg`}
                        alt={'purplecoin'}
                        />
                            <span className={classes.coin_count}>{user ? coins : ""}</span>
                        </Link>
                    </li>}
                    {
                        user?
                        <li className={classes.menu_container}>
                            <Link to="/profile">{user.username}</Link>
                            <div className={classes.menu}>
                                {/*<Link to="/profile">Profile</Link>*/}
                                <a onClick={logout}>Logout</a>
                            </div>
                        </li> : (
                        <Link to="/login" className={classes.loginButton}>Login</Link>
                    )}
                </ul>
            </nav>
        </div>
    </header>;
}