import React from "react";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Link } from 'react-router-dom';
import classes from './footer.module.css';
import { useAuth } from "../../hooks/useAuth";

const Footer = () => {
  const { user } = useAuth();
  return (
    <div className={classes.footerWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.footerSectionOne}>
          <div className={classes.footerLogoContainer}>
            <img src={`/Xbznpd01.svg`} alt="Logo" />
          </div>
        </div>
        <div className={classes.footerSectionTwo}>
          <div className={classes.footerSectionColumns}>
            {user && <span><Link className={classes.footerText} to={"/tasks"}>25% commission with your Promo Code:</Link></span>}
            {user && <span><Link className={classes.footerText} to={"/tasks"}>{`${user.id}`}</Link></span>}
          </div>
        </div>
        <div className={classes.footerSectionThree}>
          <div className={classes.footerSectionColumns}>
            <span><Link className={classes.footerText} to={"/terms"}>Terms & Conditions</Link></span>
            <span><Link className={classes.footerText} to={"/privacypolicy"}>Privacy Policy</Link></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;