import classes from './surveys.module.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import StarRating from '../../components/StarRating/StarRating';
import Button from '../../components/Button/Button';

const SurveyList = () => {
    const { user } = useAuth();
    const [ip, setIP] = useState("");
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getUserIP = async () => {
            const res = await axios.get("https://api.ipify.org/?format=json");
            console.log(res.data);
            setIP(res.data.ip);
          };

        const fetchSurveys = async () => {
            try {
                const CPXResponse = await fetch(`https://live-api.cpx-research.com/api/get-surveys.php?app_id=21139&&ext_user_id=${user.id}&output_method=api&ip_user=${ip}&limit=30`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        app_id: 21139,
                        ext_user_id: user.id,
                        output_method: 'api',
                        ip_user: ip,
                        limit: 30
                    },
                });
                {/*}
                const revlumResponse = await fetch(`https://revlum.com/api?apikey=zjhkabyyxck1k1eq4x3tq2ywn4ot48&type=surveys`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        apikey: "zjhkabyyxck1k1eq4x3tq2ywn4ot48"
                    },
                }); */}

                const CPXData = await CPXResponse.json();
                //const revlumData = await revlumResponse.json();
                //const mergedSurveys = [...CPXData.surveys, ...revlumData];
                setSurveys(CPXData.surveys);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        getUserIP();
        fetchSurveys();
    }, [user.id, ip]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
            <div className={classes.categories}>
        <Button className={classes.button} text="Explore" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/tasks'; }}/>
        <Button className={classes.button} text="Games" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/games'; }}/>
        <Button className={classes.button} text="Surveys" color="black" backgroundColor="white" width="8rem" height="2rem" fontSize="1rem"/>
        <Button className={classes.button} text="Offers" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/offers'; }}/>

        </div>
    <h2 className={classes.sponsoredOffersHeading}>Available Surveys</h2>
    <ul className={classes.list}>
    {surveys.map(survey => (
      <li key={survey.id}>
        <a href={survey.href_new}>
          <img className={classes.image} src="logo2-cpx-reserach.png" alt="CPX Research" />
          <div className={classes.content}>
            <span className={classes.popular}>Payout: 💰 {survey.payout}</span>
            {survey.description && (<span className={classes.popular}>{survey.description}</span>)}
          </div>
        </a>
      </li>
    ))}
  </ul>
  </>
  );
};

export default SurveyList;