import classes from './modal.module.css';
import React from 'react'

export default function CannotAffordModal({ closeModal }) {
  return (
    <div className={classes.background}>
    <div className={classes.container}>
        <div className={classes.xButton}>
        <button onClick={() => closeModal(false)}>X</button>
        </div>
        <div className={classes.title}>
            <h1>Not Enough Coins!</h1>
        </div>
        <div className={classes.body}>
            <p>You do not have enough coins yet to redeem this reward. 
                Keep completing tasks to earn more coins!
            </p>
        </div>
        <div className={classes.footer}>
            <button onClick={() => closeModal(false)} id="cancelbtn">Ok</button>
        </div>
    </div>
  </div>
  )
}
