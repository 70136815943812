import React from 'react'
import { useAuth } from '../../hooks/useAuth'

export default function CPXResearch() {

    const { user } = useAuth();

    console.log(user._id);
    
  return (
    <iframe width="100%" frameBorder="0" height="1000px"  src={`https://offers.cpx-research.com/index.php?app_id=21139&ext_user_id=${user.id}&username=${user.username}&email=${user.email}`}></iframe>
  )
}
