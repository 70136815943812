import React from 'react'
import classes from './terms.module.css'

export default function Terms() {
  return (
    <div className={classes.main}>
    <h1 className={classes.heading}>Terms & Conditions</h1>
    <p className={classes.subsection}><strong>Last Updated:</strong> February 15, 2024</p>

<p className={classes.subsection}>These Terms of Service ("Terms") govern your use of RewardRocket ("we," "us," or "our") website located at rewardrocket.app (the "Site"). By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Site.</p>

<h2 className={classes.section}>1. Use of the Site</h2>

<p className={classes.subsection}>a. <strong>Eligibility:</strong> You must be at least 18 years old, or 16 years old with a guardian's permission, to use the Site. By using the Site, you represent and warrant that you are at least 13 years old.</p>

<p className={classes.subsection}>b. <strong>Account Registration:</strong> In order to access certain features of the Site, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated.</p>

<p className={classes.subsection}>c. <strong>User Conduct:</strong> You agree to use the Site in compliance with all applicable laws and regulations and in a manner that does not infringe or violate the rights of any third party.</p>

<h2 className={classes.section}>2. Content</h2>

<p className={classes.subsection}>a. <strong>User Content:</strong> You may have the opportunity to submit content, such as survey responses or comments, through the Site ("User Content"). You retain ownership of any User Content you submit, but by submitting User Content, you grant us a worldwide, non-exclusive, royalty-free, fully paid-up, perpetual, irrevocable, and transferable license to use, reproduce, distribute, modify, adapt, display, perform, and otherwise exploit the User Content.</p>

<p className={classes.subsection}>b. <strong>Our Content:</strong> All content on the Site is owned by us or our licensors and is protected by copyright, trademark, and other intellectual property laws.</p>

<h2 className={classes.section}>3. Privacy</h2>

<p className={classes.subsection}>Your use of the Site is subject to our Privacy Policy, which is incorporated by reference into these Terms. Please review the Privacy Policy carefully for information about how we collect, use, and disclose your personal information.</p>

<h2 className={classes.section}>4. Modifications to the Site</h2>

<p className={classes.subsection}>We reserve the right to modify or discontinue the Site or any features or services offered through the Site at any time without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuance of the Site or any part thereof.</p>

<h2 className={classes.section}>5. Limitation of Liability</h2>

<p className={classes.subsection}>In no event shall we be liable to you or any third party for any indirect, consequential, incidental, special, or punitive damages, including lost profits, arising from your use of the Site or any content or services available through the Site.</p>
<p className={classes.subsection}>By using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Site.</p>

<h2 className={classes.section}>9. Contact Us</h2>

<p className={classes.subsection}>If you have any questions about the rights and restrictions above, please contact us at cashoutnowllc@gmail.com</p>

    </div>

)}
