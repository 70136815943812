import React from "react";

const HowItWorks = () => {
  const workInfoData = [
    {
      image: `iphone-svgrepo-com.svg`,
      title: "Complete Offers",
      text: "Play games, answer surveys, or complete a sponsored offer.",
    },
    {
      image: `dollar-sign-money-bag-svgrepo-com.svg`,
      title: "Choose Payment Method",
      text: "From PayPal to Amazon gift cards, we have you covered, with more to come!",
    },
    {
      image: `payment-svgrepo-com.svg`,
      title: "Collect Your Rewards",
      text: "Receive your rewards directly without leaving your home!",
    },
  ];
  return (
    <div className="work-section-wrapper">
        <div className="about-background-image-container">
        <img src={`aboutbackground.png`} alt="" />
      </div>
      <div className="work-section-top">
        <p className="primary-subheading">Earn</p>
        <h1 className="primary-heading">How It Works</h1>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;