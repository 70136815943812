import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classes from './redeemMethod.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CannotAffordModal from '../Modal/CannotAffordModal';
import { useAuth } from '../../hooks/useAuth';

export default function RedeemMethod() {


    const [openModal, setOpenModal] = useState(false);
    const [openCannotAffordModal, setOpenCannotAffordModal] = useState(false);
    const [selectedCoinAmount, setSelectedCoinAmount] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);


    const { user } = useAuth();

    const [coins, setCoins] = useState(user?.coins || 0); // Initialize with existing coins

    useEffect(() => {
        const pollData = async () => {
            if (user) {
            const response = await axios.get(`https://www.rewardrocket.app/api/users/coins-count?user_id=${user.id}`);
            setCoins(response.data.coins); // Assuming response contains updated coins
            }
        };

        const intervalId = setInterval(pollData, 5000); // Poll every 5 seconds

        return () => clearInterval(intervalId); // Clear interval on unmount
    }, [user]); // Re-run effect if user changes


  return (
    <ul className={classes.list}>
        <li>
            <a>
            <span className={classes.title}>PayPal Cashout</span>
                <img
              className={classes.image}
              src={`/paypal-svgrepo-com.svg`}
              alt={"paypal"}
            />
          <Button width={"6rem"} height={"2rem"} text={"Select"} onClick={() => { window.location.href = '/redeem/paypal'; }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>Visa Card</span>
                <img
              className={classes.image}
              src={`/visa.jpeg`}
              alt={"visa"}
            />

          <Button width={"6rem"} height={"2rem"} text={"Select"} onClick={() => { window.location.href = '/redeem/visa'; }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>Amazon Gift Card</span>
                <img
              className={classes.image}
              src={`/amazongiftcardlogo.png`}
              alt={"amazon"}
            />
          <Button width={"6rem"} height={"2rem"} text={"Select"} onClick={() => { window.location.href = '/redeem/amazon'; }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>Coinbase USDC</span>
                <img
              className={classes.image}
              src={`/coinbaselogoflat.png`}
              alt={"coinbase"}
            />
          <Button width={"6rem"} height={"2rem"} text={"Select"} onClick={() => { window.location.href = '/redeem/coinbase'; }}/>
          </a>
        </li>
        {openModal && <Modal closeModal={setOpenModal} coin_amount={selectedCoinAmount} method={selectedMethod}/>}
        {openCannotAffordModal && <CannotAffordModal closeModal={setOpenCannotAffordModal}/>}
    </ul>
  );
}