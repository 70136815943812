import {React, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import Title from '../../components/Title/Title';
import classes from './registerPage.module.css';
import Button from '../../components/Button/Button';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export default function RegisterPage() {
    const auth = useAuth();
    const { user } = auth;
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const returnUrl = params.get('returnUrl');
    const referrer = params.get('ref');
    const clickId = params.get('clickId');

    useEffect(() => {
        if (!user) return;
        returnUrl ? navigate(returnUrl) : navigate('/tasks');
    }, [user]);

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors },
    } = useForm();

    const submit = async data => {
        if (referrer) {
          data.referrer = referrer;
        }
        await auth.register(data);
    };

  return (<div className={classes.container}>
    <div className={classes.details}>
        <Title className={classes.heading} title="Join for free today!" margin="1rem"/>
        <form onSubmit={handleSubmit(submit)} noValidate>
        <Input
            type="text"
            label="Username"
            {...register('username', {
              required: true,
              minLength: 5,
            })}
            error={errors.name}
          />

          <Input
            type="email"
            label="Email"
            {...register('email', {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: 'Email Is Not Valid',
              },
            })}
            error={errors.email}
          />

          <Input
            type="password"
            label="Password"
            {...register('password', {
              required: true,
              minLength: 5,
            })}
            error={errors.password}
          />

          <Input
            type="password"
            label="Confirm Password"
            {...register('confirmPassword', {
              required: true,
              validate: value =>
                value !== getValues('password')
                  ? 'Passwords Do No Match'
                  : true,
            })}
            error={errors.confirmPassword}
          />

          <Input
            type="text"
            label="Optional Promo Code"
            {...register('promoCode', {
              required: false,
              value: clickId || ''
            })}
            error={errors.confirmPassword}
          />

          <div className={classes.login}>
          <input 
          type="checkbox" 
          id="termsCheckbox" 
          className={classes.checkbox} 
          {...register('terms', {
            required: '*You must agree to the above*',
        })}
          />
          <label className={classes.agree} for="termsCheckbox">I have read and agree to RewardRocket's <a className={classes.termLink} href="/terms">Terms of Service</a> and <a className={classes.termLink} href="/privacypolicy">Privacy Policy</a></label>
          
          </div>
          {errors.terms && (
              <span role="alert" className={classes.error}>
              {errors.terms.message}
            </span>
          )}

          <Button type="submit" text="Register" color="white" backgroundColor="black" />

          <div className={classes.login}>
            Already a user? &nbsp;
            <Link className={classes.termLink} to={`/login${returnUrl ? '?returnUrl=' + returnUrl : ''}`}>
              Login here
            </Link>
          </div>
        </form>
    </div>
  </div>
  
  )

}
