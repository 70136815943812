import classes from './offersOnly.module.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import StarRating from '../../components/StarRating/StarRating';
import Button from '../../components/Button/Button';
import WallOffer from '../../components/WallOffer/WallOffer';
import jsonp from 'jsonp';
import fetchJsonp from 'fetch-jsonp';
import { getRevlumOffers } from '../../services/revlumService';
import { getWannadsOffers } from '../../services/wannadsService';
import { getUserGeo } from '../../services/userGeoService';
import { getCpaLeadOffers } from '../../services/cpaLeadService';
import { getLootablyOffers } from '../../services/lootablyService';

const OffersOnly = () => {
    const { user } = useAuth();
    const [ip, setIP] = useState("");
    const [surveys, setSurveys] = useState([]);
    const [offers, setOffers] = useState([]);
    const [wannadsOffers, setWannadsOffers] = useState([]);
    const [revlumOffers, setRevlumOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIPAndCountry = async () => {
            try {
                const ipRes = await axios.get("https://api.ipify.org/?format=json");
                const fetchedIP = ipRes.data.ip;
                setIP(fetchedIP);

                const geoRes = await getUserGeo(fetchedIP);
                const fetchedCountry = geoRes.country_code2;
                setCountry(fetchedCountry);

                console.log("User IP is " + fetchedIP);
                console.log("User country is " + fetchedCountry);
            } catch (error) {
                setError(error.message ? error.message : JSON.stringify(error));
                setLoading(false);
            }
        };

        fetchIPAndCountry();
    }, []);

    useEffect(() => {
        const fetchOffers = async () => {
            if (user.id && ip && country) {
                try {

                    const [lootablyData] = await Promise.all([
                        getLootablyOffers()
                    ]);

                    const lootablyArray = lootablyData.data.offers;

                    const filteredOffers = lootablyArray.filter(offer => 
                        !(offer.categories.includes("game") || offer.categories.includes("mobilegame") || offer.categories.includes("desktopgame"))
                    );

                    const sortedOffers = filteredOffers.sort((a, b) => b.conversionRate - a.conversionRate);

                    const top30Offers = sortedOffers.slice(0, 30);

                    const transformLootablyOffers = top30Offers.map(offer => {
                        let reward;
                    
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        return {
                            id: offer.offerID,
                            title: offer.name,
                            description: offer.description,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", user.id),
                            reward: reward,
                            events: events
                        };
                    });
                
                const allOffers = [...transformLootablyOffers];
                const uniqueOffers = Array.from(new Set(allOffers.map(offer => offer.title)))
                                    .map(title => allOffers.find(offer => offer.title === title));
                setOffers(uniqueOffers);
                } catch (error) {
                    setError(error.message ? error.message : JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
            };
            fetchOffers();
    }, [user.id, ip, country]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
            <div className={classes.categories}>
        <Button className={classes.button} text="Explore" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/tasks'; }}/>
        <Button className={classes.button} text="Games" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/games'; }}/>
        <Button className={classes.button} text="Surveys" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/surveys'; }}/>
        <Button className={classes.button} text="Offers" color="black" backgroundColor="white" width="8rem" height="2rem" fontSize="1rem"/>
        </div>
    <h2 className={classes.sponsoredOffersHeading}>Available Offers</h2>
    <ul className={classes.list}>
    {offers && offers.map(offer => (
      <li key={offer.id} className={classes.item}>
        <WallOffer
          title_text={offer.title}
          center_text={offer.description}
          image_game={offer.imageUrl}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}
          link={offer.link}
          reward_payout={offer.reward}
          events={offer.events}
        />
      </li>
    ))}
  </ul>
  </>
  );
};

export default OffersOnly;